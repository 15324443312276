import * as React from 'react';
import { st, classes } from './OfferingName.st.css';
import { Text, TextTypography } from 'wix-ui-tpa';

import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../context/running-environment-context';

interface OfferingNameProps extends RunningEnvironmentProps {
  children?;
  onClick?;
  url?;
  htmlTag: string;
}

export const OfferingNameComponent = ({
  runningEnvironment,
  onClick,
  children,
  url,
  htmlTag,
}: OfferingNameProps) => {
  const { isMobile, isEditorMode, isPreviewMode } = runningEnvironment;
  const isLiveSite = !isEditorMode && !isPreviewMode;
  let hrefAttribute;
  if (isLiveSite && url) {
    // GBT: this is because in old platform there is no url, and it causes an empty href.
    hrefAttribute = { href: url };
  } else {
    hrefAttribute = {};
  }
  return (
    <div
      className={classes.root}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
    >
      <a
        {...hrefAttribute}
        className={classes.link}
        role="link"
        tabIndex={0}
        aria-label={children || 'Meeting Name'}
      >
        <Text
          className={st(classes.offeringName, {
            disableFontSize: isMobile,
            isMobile,
          })}
          data-hook="offering-name-title"
          typography={TextTypography.smallTitle}
          tagName={htmlTag}
        >
          {children}
        </Text>
      </a>
    </div>
  );
};

export const OfferingName = withRunningEnvironmentContext(
  OfferingNameComponent,
);
